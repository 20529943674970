.Home {
    width: 100%;
    max-width: 600px;
}

@media only screen and (max-width: 400px) {
    /* For mobile phones: */
    .Home {
        max-width: 100%;
    }
  }

.PDF {
    display: block;
    width: 100%;
    height: 100vh;
    border: none;
}

.Logo {
    padding: 16px;
    max-width: 300px;
    margin: auto;
}